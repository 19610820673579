<template>
  <div class="style-demo">
    <header-bar v-if="mode === 'normal'">
      <template #left>
        <div class="title style-demo-title" style="font-weight: 700; cursor: pointer">
          <div>样式统配(样式demo)</div>
        </div>
      </template>
      <template #right>
        <!-- 按钮组 -->
        <div class="btn-box">
          <el-button size="mini" type="primary" @click="onComplete">保存</el-button>
          <el-button size="mini" @click="goBack()">返回</el-button>
        </div>
      </template>
    </header-bar>
    <div class="body">
      <div class="menu" v-if="mode === 'normal'">
        <el-menu :default-active="$route.path">
          <template v-for="item in menus">
            <el-submenu v-if="item.children" :index="item.path" :key="item.path">
              <template slot="title">
                <svg-icon :name="item.icon" class="icon m-r-5 m-l-5" v-if="item.icon"></svg-icon>
                <i v-if="item.elIcon" :class="`el-icon-${item.elIcon}`"></i>
                <span slot="title">{{ item.name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-for="menu in item.children" :key="menu.path" :index="menu.path"
                  @click="switchMenu(menu.path)">{{ menu.name }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-else :index="item.path" :key="item.path" @click="switchMenu(item.path)">
              <svg-icon :name="item.icon" class="icon m-r-5" v-if="item.icon"></svg-icon>
              <i v-if="item.elIcon" :class="`el-icon-${item.elIcon}`"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="container" v-loading="loading" element-loading-text="数据加载中..."
        element-loading-background="rgba(255, 255, 255, 1)">
        <div style="padding: 5px;">
          <router-view v-if="styleData" :styleConfig="styleConfig" :styleData="styleData" :mode="mode"
            :json="json" :merge="{ mergeDecoration, mergekeyWord }" ref="component" @save="onComplete"
            @setComponent="setComponent" @changeJson="changeJson"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar';
import { BookRender } from 'book-preview';
import {
  defaultQuestion, defaultChapter, defaultParagraph, defaultColumn, defaultGroupStyle, getDefaultDecoration, getDefaultDynamicDecoration
  , defaultMargin, defualtPageNumber, defaultMarginImg, defaultMarginText, defaultKeyWord, defaultext_cfg
} from '@/scripts/styleToolDefault';
import { mergePeer } from '@/scripts/helper';

import _ from 'lodash';
import { catalog } from './assets/componentCatalog.js';
import defaultStyle from './assets/defaultStyle.js';
import { getOrder } from '@/api/placeOrder';

export default {
  name: 'style-demo',
  components: {
    HeaderBar
  },
  computed: {
    menus () {
      return [
        {
          name: '全局配置',
          icon: 'book',
          path: '/style-demp/global-config',
          children: [
            {
              name: '导出版本',
              path: '/style-demo/book-version',
            },
            {
              name: '纸张版心',
              path: '/style-demo/book-core',
            },
            {
              name: '文字样式',
              path: '/style-demo/book-font-style',
            }
          ]
        },
        {
          name: '书眉边栏',
          path: '/style-demo/book-margin',
          elIcon: 'notebook-2',
          children: [{
            name: '页眉',
            path: '/style-demo/book-header'
          }, {
            name: '页脚',
            path: '/style-demo/book-footer'
          },
          {
            name: '边栏',
            path: '/style-demo/book-sidebar'
          },
          // {
          //   name: '水印',
          //   path: '/style-demo/book-watermark'
          // }
          ]
        },
        {
          name: '正文内容',
          path: '/style-demo/text-content',
          elIcon: 'folder',
          children: [
            {
              name: '标题',
              path: '/style-demo/chapter-class-config'
            },
            // {
            //   name: '段落',
            //   path: '/style-demo/paragraph-config'
            // },
            {
              name: '试题',
              path: '/style-demo/question-config'
            }
          ]
        }
      ];
    },
    mode () {
      return this.$route.meta.noCHeader ? 'guide' : 'normal';
    },
  },
  data () {
    return {
      catalog,
      fileVal: '',
      dialogVisible: false,
      setComponentCb: null,
      actPreview: '',
      previewHint: '',
      form: null,
      bookId: this.$route.query.book_id
        // || '5fd18b2ecac877d70794356a', //语文
        || '5fcf8033bf780e477d3a0ee3', // 外网
      projectId: this.$route.query.project_id,
      styleId: this.$route.query.style_id,
      loading: true,
      styleData: null,
      json: [],
      previewLoading: false,
      quickPreviewLoading: false,
      GenerateWordLoading: false,
      book_type: 'teacher',
      rules: {
        'data.name': [{ required: true, trigger: 'change' }],
        type: [{ required: true, trigger: 'change' }],
        model: [{ required: true, trigger: 'change' }],
        'data.img': [{ required: true, trigger: 'change' }]
      },
      styleConfig: null,
      styleInfo: null,
      sampleName: '',
      sampleFileName: '',
      sampleJson: '',
      sampleGenerationDialog: false,
      sampleTypevisible: false,
      sampleSubjectData: {
        subjectsAndGrade: [],
        stages: []
      },
      samplebookData: {
        book_id: ''
      },
      sendData: null,
    };
  },
  watch: {
    styleData: {
      deep: true,
      handler (val) { console.log(val); }
    }
  },
  async created () {
    // TODO 改传递
    if (this.$route.query.id) {
      const form = await getOrder({
        ticket_id: this.$route.query.id
      });
      await this.init(form.xdoc_planning?.[0]?.config?.style_config_data || null);
    } else {
      await this.init();
    }
  },
  destroyed () {
    if (this.sendData) {
      this.$EventBus.$emit('styleData', this.sendData);
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    async init (data) {
      let styleData;
      if (data) {
        styleData = data;
      } else {
        styleData = defaultStyle;
      };
      const json = [];

      // 如果没有自动处理选项，则处理后再提交1次
      if (Object.keys(styleData).length && !styleData.components[0].auto_choice_list) {
        const bookRender = new BookRender();
        bookRender.setData({ json, config: styleData });
        const cols = bookRender.computeAutoChoiceCols();
        styleData.components[0].auto_choice_list = cols;
      }

      this.formatData(styleData);

      // fix data start
      // 因为一些历史数据没有水印图的position信息，页面就白屏报错，这里修正一下
      styleData.components[0].margin_components.forEach(item => {
        if (item.data && item.data.length) {
          item.data.forEach(i => {
            if (i.node_type === 'image' && !i.position) {
              i.position = {
                x: '0mm',
                y: '0mm',
              };
            }
          });
        }
      });
      // fix data end

      // 处理额外配置
      styleData.components[0].ext_cfg = mergePeer(defaultext_cfg(), styleData.components[0].ext_cfg);

      this.styleData = styleData;
      this.loading = false;
    },

    goBack () {
      // this.$router.push(`/book/detail?project_id=${this.$route.query.project_id}`)
      this.$router.push(`/create?id=${this.$route.query.id}`);
    },

    changeJson (json) {
      this.styleData = this.formatData(json);
    },

    setComponent (form, cb) {
      this.form = _.cloneDeep(form);
      this.dialogVisible = true;
      this.setComponentCb = cb;
    },

    mergeDecoration (component) {
      if (typeof component !== 'object' || !component) return;
      if (Array.isArray(component)) {
        component.forEach(item => this.mergeDecoration(item));
        return;
      }
      Object.keys(component).forEach(k => {
        if (k === 'decorations') {
          // 处理
          if (component[k].length === 0) return;
          component[k] = component[k].map(item => {
            if (item.type === 'dynamic_image') {
              // 动态图
              const dynamic_image_cfg = [];
              if (item.dynamic_image_cfg) {
                item.dynamic_image_cfg.forEach((dynamicitem) => {
                  dynamic_image_cfg.push(mergePeer(getDefaultDynamicDecoration(), dynamicitem));
                });
              }
              const decoration = mergePeer(getDefaultDecoration(), item);
              decoration.dynamic_image_cfg = dynamic_image_cfg;
              return decoration;
            } else {
              return mergePeer(getDefaultDecoration(), item);
            }
          });
          return;
        }
        if (typeof component[k] === 'object' && component[k]) this.mergeDecoration(component[k]);
      });
    },
    mergekeyWord (component) {
      if (typeof component !== 'object' || !component) return;
      if (Array.isArray(component)) {
        component.forEach(item => this.mergekeyWord(item));
        return;
      }
      Object.keys(component).forEach(k => {
        if (k === '_keyword') {
          // 处理
          component[k] = component[k].map(item => mergePeer(defaultKeyWord(), item));
          return;
        }
        if (typeof component[k] === 'object' && component[k]) this.mergekeyWord(component[k]);
      });
    },
    formatData (styleData) {
      const { components: [component], book_default_style: { global_color }, book_default_style, style_bank } = styleData;

      // 新增样式库数据
      if (!style_bank) {
        this.$set(styleData, 'style_bank', {
          title: [],
          question: [],
          paragraph: []
        });
      }

      Object.keys(styleData.style_bank).forEach(key => {
        styleData.style_bank[key] = styleData.style_bank[key].map(item => {
          let style_object;
          if (key === 'title') {
            style_object = defaultChapter({ identity: { content: { level: '' }, _parent: {} } });
          } else if (key === 'question') {
            style_object = defaultQuestion({ identity: { content: { level: '' }, _parent: {} } });
          } else {
            style_object = defaultParagraph({ identity: { content: { level: '' }, _parent: {} } });
          }
          return mergePeer({ style_name: '', style_object }, item);
        });
      });

      const { page_cfg, page_cfg: { global_column }, default_style } = component;
      page_cfg.global_column = mergePeer(defaultColumn(), global_column);

      if (!global_color) book_default_style.global_color = [];

      if (default_style.line_spacing == null) default_style.line_spacing = '';

      if (!styleData.extra_font_list) styleData.extra_font_list = [];

      // 方框样式
      component.group_style_list = component.group_style_list?.map(item => {
        return mergePeer(defaultGroupStyle({ name: '', id: '' }), item);
      }) || [];

      // 页眉页脚边栏水印
      component.margin_components = component.margin_components?.map(item => {
        return mergePeer(defaultMargin(), item);
      }) || [];
      component.margin_components.forEach(item => {
        item.data = item.data.map(item => {
          if (item.node_type === 'page_number') {
            return mergePeer(defualtPageNumber(), item);
          }
          if (item.node_type === 'image') {
            return mergePeer(defaultMarginImg(), item);
          }
          if (item.node_type === 'text') {
            return mergePeer(defaultMarginText(), item);
          }
          return item;
        });
      });
      // 处理 decoration
      this.mergeDecoration(component);
      // 处理 _keyword
      this.mergekeyWord(component);
      component.class_cfg_list = component.class_cfg_list?.map(item => {
        if (item.identity.node_type === 'question') {
          return mergePeer(defaultQuestion({ identity: item.identity }), item);
        }
        if (item.identity.node_type === 'chapter') {
          return mergePeer(defaultChapter({ identity: { content: { level: '' }, _parent: {}, ...item.identity } }), item);
        }
        if (item.identity.node_type === 'paragraph') {
          return mergePeer(defaultParagraph({ identity: item.identity }), item);
        }
        return item;
      }) || [];

      component.node_batch_cfg_list = component.node_batch_cfg_list?.map(item => {
        if (item.node_type === 'paragraph') {
          return mergePeer(defaultParagraph({ id: '', name: '', node_id_list: [], node_type: '' }), item);
        }
        if (item.node_type === 'chapter') {
          return mergePeer(defaultChapter({ id: '', name: '', node_id_list: [], node_type: '' }), item);
        }
        if (item.node_type === 'question') {
          return mergePeer(defaultQuestion({ id: '', name: '', node_id_list: [], node_type: '' }), item);
        }
      }) || [];
      return styleData;
    },
    async onComplete () {
      this.loading = true;
      const data = _.cloneDeep(this.styleData);
      // const data = removeIfEmpty(this.styleData);
      // 清除掉没有 node_id_list 存在的批量配置
      const [component] = data.components;
      if (component.node_batch_cfg_list) {
        component.node_batch_cfg_list = component.node_batch_cfg_list.filter(item => item.node_id_list);
      }
      // 若 fbd.enable_fbd 为 false，并且 code_template 值为空，则删掉当前字段
      if (component.margin_components) {
        component.margin_components.forEach(component => {
          if (component.fbd && !component.fbd.enable_fbd && !component.fbd.code_template) {
            delete component.fbd;
          }
        });
      }
      this.loading = false;
      this.$message.success('保存成功');
      this.$router.push(`/create?id=${this.$route.query.id}`);
      // TODO: 这里需要放到beforeDestory里面吗
      this.sendData = data;
    },
    switchMenu (val) {
      const { path, params, query } = this.$route;
      if (path !== val) {
        this.$router.replace({ path: val, query, params });
      }
    },
  }

};
</script>

<style lang="scss" scoped>
// 20210420 改版v2样式demo
@import "./assets/restyle.scss";

.style-demo {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .body {
    flex: auto;
    overflow: hidden;
    display: flex;

    .menu {
      >* {
        height: 100%;
      }

      &:not(.el-menu--collapse) {
        width: 200px;
        flex: none;
        background-color: #fff;
      }

      .icon {
        margin-right: 5px;
      }
    }

    .container {
      flex: auto;
      overflow: auto;
      // > * {
      //   height: 100%;
      // }
    }
  }
}

.sampleTypevisible-container>div {
  margin-bottom: 20px;
}

.typemask {
  margin-top: 10px;
}

.sampleType-container-footer {
  text-align: center;
}
.sampleSubject>div{
  align-items:center;
}
.sampleName{
  display:flex;
  align-items:center;
  .el-input{
    width:220px;
  }
}
.oldversion-btn{
  margin-right:10px;
}
.oldversion-container>*{
  margin-bottom:10px;
}
.style-demo-title{
  display:flex;
  align-items:center;
}
.style-demo-title>div{
  margin-right:10px;
}
</style>
