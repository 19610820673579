export default {
  book_page_cfg: {
    page_size: {
      name: 'A4',
      width: '210mm',
      height: '297mm',
    },
    page_core_size: {
      width: '180mm',
      height: '267mm',
    },
    page_margin: {
      top: '15mm',
      bottom: '15mm',
      left: '15mm',
      right: '15mm',
    },
    paper_orientation: 'portrait',
    global_column: {
      column_count: 2,
      column_gap: '10mm',
    },
    global_color: [],
  },
  book_default_style: {
    font_size: '10.5pt',
    font_family_cn: 'SongTi',
    font_family_en: 'Baizheng',
    font_family_num: 'Baizheng',
    font_color: { rgb: [0, 0, 0] },
    text_align: 'left',
    line_spacing: 0.5,
  },
  components: [
    {
      type: 'text',
      page_cfg: {
        page_size: {
          name: 'A4',
          width: '210mm',
          height: '297mm',
        },
        page_core_size: {
          width: '180mm',
          height: '267mm',
        },
        page_margin: {
          top: '15mm',
          bottom: '15mm',
          left: '15mm',
          right: '15mm',
        },
        paper_orientation: 'portrait',
        global_column: {
          column_count: 2,
          column_gap: '10mm',
        },
      },
      default_style: {
        font_size: '10.5pt',
        font_family_cn: 'SongTi',
        font_family_en: 'Baizheng',
        font_family_num: 'Baizheng',
        font_color: { rgb: [0, 0, 0] },
        text_align: 'left',
        line_spacing: 0.5,
      },
      class_cfg_list: [
        {
          identity: {
            content: {
              level: 1,
            },
            _parent: {},
            node_type: 'chapter',
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              line_height: '',
              text_decoration: '',
            },
          },
          display: {
            _self: true,
            body: true,
            qrcode: false,
          },
          config: {
            _self: {
              column: {
                column_count: '',
                column_gap: '',
                line_type: '',
                column_weight: '',
                line_color: {
                  rgb: '',
                  cmyk: [],
                },
              },
              hanging_space: '',
              margin: {
                before: '',
                after: '',
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              is_in_column: '',
              page_break: '',
              decorations: [],
              is_chapter_num_zk: true,
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
          },
          html: {
            _self: {
              _keyword: [],
            },
          },
          html_style_clean: {},
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          identity: {
            content: {
              level: 2,
            },
            _parent: {},
            node_type: 'chapter',
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              line_height: '',
              text_decoration: '',
            },
          },
          display: {
            _self: true,
            body: true,
            qrcode: false,
          },
          config: {
            _self: {
              column: {
                column_count: '',
                column_gap: '',
                line_type: '',
                column_weight: '',
                line_color: {
                  rgb: '',
                  cmyk: [],
                },
              },
              hanging_space: '',
              margin: {
                before: '',
                after: '',
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              is_in_column: '',
              page_break: '',
              decorations: [],
              is_chapter_num_zk: true,
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
          },
          html: {
            _self: {
              _keyword: [],
            },
          },
          html_style_clean: {},
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          identity: {
            content: {
              level: 3,
            },
            _parent: {},
            node_type: 'chapter',
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              line_height: '',
              text_decoration: '',
            },
          },
          display: {
            _self: true,
            body: true,
            qrcode: false,
          },
          config: {
            _self: {
              column: {
                column_count: '',
                column_gap: '',
                line_type: '',
                column_weight: '',
                line_color: {
                  rgb: '',
                  cmyk: [],
                },
              },
              hanging_space: '',
              margin: {
                before: '',
                after: '',
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              is_in_column: '',
              page_break: '',
              decorations: [],
              is_chapter_num_zk: true,
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
          },
          html: {
            _self: {
              _keyword: [],
            },
          },
          html_style_clean: {},
          fbd: {
            enable_fbd: true,
            code_template: '',
          },
        },
        {
          identity: {
            node_type: 'question',
            content: {},
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              fbd: {},
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            body: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            serial_number: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '11111',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '2222222222',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            source: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '333333333333',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            answer: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            analysis: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choices: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_letter: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_option: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
          },
          config: {
            body: {
              indent: '',
              blank_with_brackets: false,
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            bracket: {
              autofill: '',
              float: '',
              length: '',
              length_cfg: {
                mode: 'full',
                length: 2,
              },
            },
            _self: {
              hanging_space: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              continuous_array: {
                effect: '',
                width: '',
              },
              children_dw: {
                per_line: '',
                spacing: [],
              },
              blank_both_sides_space: '',
            },
            serial_number: {
              layout: '',
              pattern: '',
              margin: {
                before: '',
                after: '',
              },
              decorations: [],
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            answer: {
              is_in_answer_space: '',
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            analysis: {
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            choices: {
              option_spacing: [],
              choice_per_line: '',
              letter_layout: '',
              image_option_letter_position: '',
              margin: {
                left: '',
                top: '',
              },
            },
            answer_space: {
              height: '',
              layout: 'default',
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
            fk: {
              group: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              padding: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              background: {
                rgb: [],
                cmyk: [],
              },
              border_type: '',
              border_image: {
                header: '',
                footer: '',
                left: '',
                right: '',
              },
              border: {
                type: '',
              },
            },
            content_split: {
              refer_order: 'default',
              refer_alternate: false,
              answer: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              analysis: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              cross_cfg: {
                answer_analysis_arrangement_mode: 'break_line',
              },
            },
          },
          html: {
            _self: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
              table: {
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                text_bold: '',
                font_color: {
                  cmyk: [],
                },
                line_spacing: '',
                text_align: '',
                font_background: {
                  cmyk: [],
                },
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                after: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                font_style: {
                  top: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                  left: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
                fill: {
                  crma: {
                    rgb: '',
                    cmyk: [],
                  },
                  top: {
                    rgb: '',
                    cmyk: [],
                  },
                  left: {
                    rgb: '',
                    cmyk: [],
                  },
                },
                border: {
                  outer: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                  inner: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                },
              },
            },
            body: {
              _keyword: [],
              img: {
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            answer: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            analysis: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            choices: {
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
          },
          display: {
            _self: null,
            body: null,
            serial_number: null,
            source: null,
            answer: null,
            analysis: null,
            choices: null,
            choice_letter: null,
            choice_option: null,
            answer_space: null,
            qrcode: null,
          },
          html_style_clean: {},
        },
        {
          identity: {
            node_type: 'question',
            content: {
              question_type: 'choice',
            },
          },
          style: {
            _self: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            body: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            serial_number: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            source: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            answer: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            analysis: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choices: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_letter: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
            choice_option: {
              font_size: '',
              font_family_cn: '',
              font_family_en: '',
              font_family_num: '',
              text_bold: '',
              font_color: {
                cmyk: [],
              },
              line_spacing: '',
              text_align: '',
              font_background: {
                cmyk: [],
              },
              before: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
              after: {
                type: '',
                content: '',
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                font_color: {
                  cmyk: [],
                },
                font_background: {
                  cmyk: [],
                },
                decorations: [],
              },
            },
          },
          config: {
            body: {
              indent: '',
              blank_with_brackets: false,
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            bracket: {
              autofill: '',
              float: '',
              length: '',
              length_cfg: {
                mode: 'full',
                length: 2,
              },
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            _self: {
              hanging_space: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
              },
              indent: '',
              continuous_array: {
                effect: '',
                width: '',
              },
              children_dw: {
                per_line: '',
                spacing: [],
              },
              blank_both_sides_space: '',
            },
            serial_number: {
              layout: '',
              pattern: '',
              margin: {
                before: '',
                after: '',
              },
              decorations: [],
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            answer: {
              is_in_answer_space: '',
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            analysis: {
              indent: '',
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            choices: {
              option_spacing: [],
              choice_per_line: '',
              letter_layout: '',
              image_option_letter_position: '',
              margin: {
                left: '',
                top: '',
              },
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
            answer_space: {
              height: '',
              layout: 'default',
            },
            qrcode: {
              text: 'http://publish.hexin.im/api/pool/short/map?id={node_id}',
              display: '',
              size: {
                width: '15mm',
                height: '15mm',
              },
              align: '',
            },
            fk: {
              group: '',
              margin: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              padding: {
                top: '',
                bottom: '',
                left: '',
                right: '',
              },
              background: {
                rgb: [],
                cmyk: [],
              },
              border_type: '',
              border_image: {
                header: '',
                footer: '',
                left: '',
                right: '',
              },
              border: {
                type: '',
              },
            },
            content_split: {
              refer_order: 'default',
              refer_alternate: false,
              answer: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              analysis: {
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                line_break: false,
                delimiter: '',
              },
              cross_cfg: {
                answer_analysis_arrangement_mode: 'break_line',
              },
            },
            choice_letter: {
              fbd: {
                enable_fbd: true,
                code_template: '',
              },
            },
          },
          html: {
            _self: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
              table: {
                font_size: '',
                font_family_cn: '',
                font_family_en: '',
                font_family_num: '',
                text_bold: '',
                font_color: {
                  cmyk: [],
                },
                line_spacing: '',
                text_align: '',
                font_background: {
                  cmyk: [],
                },
                before: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                after: {
                  type: '',
                  content: '',
                  font_size: '',
                  font_family_cn: '',
                  font_family_en: '',
                  font_family_num: '',
                  font_color: {
                    cmyk: [],
                  },
                  font_background: {
                    cmyk: [],
                  },
                  decorations: [],
                },
                font_style: {
                  top: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                  left: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
                fill: {
                  crma: {
                    rgb: '',
                    cmyk: [],
                  },
                  top: {
                    rgb: '',
                    cmyk: [],
                  },
                  left: {
                    rgb: '',
                    cmyk: [],
                  },
                },
                border: {
                  outer: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                  inner: {
                    type: '',
                    color: {
                      rgb: '',
                      cmyk: [],
                    },
                    weight: '',
                  },
                },
              },
            },
            body: {
              _keyword: [],
              img: {
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            answer: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            analysis: {
              _keyword: [],
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
            choices: {
              img: {
                display: '',
                align: '',
                insert_at: '',
                margin: {
                  left: '',
                  right: '',
                  top: '',
                  bottom: '',
                },
                desc: {
                  text: '',
                  align: '',
                  text_style: {
                    font_size: '',
                    font_family_cn: '',
                    font_family_en: '',
                    font_family_num: '',
                    text_bold: '',
                    font_color: {
                      cmyk: [],
                    },
                    line_spacing: '',
                    text_align: '',
                    font_background: {
                      cmyk: [],
                    },
                    before: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                    after: {
                      type: '',
                      content: '',
                      font_size: '',
                      font_family_cn: '',
                      font_family_en: '',
                      font_family_num: '',
                      font_color: {
                        cmyk: [],
                      },
                      font_background: {
                        cmyk: [],
                      },
                      decorations: [],
                    },
                  },
                },
              },
            },
          },
          display: {
            _self: null,
            body: null,
            serial_number: null,
            source: null,
            answer: null,
            analysis: null,
            choices: null,
            choice_letter: null,
            choice_option: null,
            answer_space: null,
            qrcode: null,
          },
          html_style_clean: {},
        },
      ],
      node_cfg_list: [],
      margin_components: [],
    },
  ],
};
